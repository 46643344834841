// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-education-js": () => import("./../src/pages/education.js" /* webpackChunkName: "component---src-pages-education-js" */),
  "component---src-pages-engagement-js": () => import("./../src/pages/engagement.js" /* webpackChunkName: "component---src-pages-engagement-js" */),
  "component---src-pages-entrepreneurship-js": () => import("./../src/pages/entrepreneurship.js" /* webpackChunkName: "component---src-pages-entrepreneurship-js" */),
  "component---src-pages-honey-js": () => import("./../src/pages/honey.js" /* webpackChunkName: "component---src-pages-honey-js" */),
  "component---src-pages-how-to-help-js": () => import("./../src/pages/how_to_help.js" /* webpackChunkName: "component---src-pages-how-to-help-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-partners-js": () => import("./../src/pages/partners.js" /* webpackChunkName: "component---src-pages-partners-js" */),
  "component---src-pages-projects-js": () => import("./../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-success-stories-js": () => import("./../src/pages/success_stories.js" /* webpackChunkName: "component---src-pages-success-stories-js" */),
  "component---src-pages-wash-pci-js": () => import("./../src/pages/wash_pci.js" /* webpackChunkName: "component---src-pages-wash-pci-js" */),
  "component---src-pages-what-we-do-js": () => import("./../src/pages/what_we_do.js" /* webpackChunkName: "component---src-pages-what-we-do-js" */),
  "component---src-pages-women-and-youth-js": () => import("./../src/pages/women_and_youth.js" /* webpackChunkName: "component---src-pages-women-and-youth-js" */),
  "component---src-pages-youth-and-environment-js": () => import("./../src/pages/youth_and_environment.js" /* webpackChunkName: "component---src-pages-youth-and-environment-js" */)
}

